import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';

import Layout from '../../components/Layout';
import Navigation from '../../components/Navigation';
import Html from '../../components/Html';
import CowaitLink from '../../components/CowaitLink';
import Flex from '../../components/Flex';

const DocsWrapper = styled.main`
  padding-left: 1rem;
  padding-right: 2rem;
  padding-top: 8rem;
  max-width: ${(p) => p.theme.page.contentWidth};
  margin: auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.small}px) {
    max-width: ${(p) => p.theme.page.contentWidth};
    padding-right: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    max-width: 33rem;
    padding-left: ${(p) => p.theme.page.leftNavMaxWidth};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.large}px) {
    padding-left: ${(p) => p.theme.page.leftNavMaxWidth};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.xlarge}px) {
    padding-left: ${(p) => p.theme.page.leftNavMaxWidth};
    padding-right: 0;
    max-width: calc(${(p) => p.theme.page.contentMaxWidth});
  }
`;

const Title = styled.h1`
  max-width: ${(p) => p.theme.page.contentMaxWidth};
  margin: 2rem auto;
`;

const DocsFooter = styled.div`
  max-width: ${(p) => p.theme.page.contentWidth};
  margin-top: 6rem;
  margin-bottom: 1rem;

  p {
    font-size: 0.85rem;
    color: ${(p) => p.theme.color.gray.default};
  }

  span {
    font-size: 0.9rem;
  }
`;

const NextPrevLinksContainer = styled(Flex.Row)`
  justify-content: space-between;
  margin: 2rem 0 6rem 0;
  padding: 1rem 0;
  font-size: 1.05rem;
  border-top: 1px solid ${(p) => p.theme.color.gray.light5};
  max-width: ${(p) => p.theme.page.contentWidth};

  div {
    min-width: 40px;

    svg {
      color: ${(p) => p.theme.color.gray.default};
    }
    a {
      padding: 0.5rem;
      color: ${(p) => p.theme.color.tertiary.default};

      &:visited {
        color: ${(p) => p.theme.color.tertiary.default};
      }
      &:hover {
        color: ${(p) => p.theme.color.tertiary.light3};
      }
    }
  }
`;

const NextPrevLinks = ({ prev, next }) => {
  return (
    <NextPrevLinksContainer>
      <div>
        {prev && (
          <>
            <HiOutlineArrowLeft />
            <Link to={prev.slug}>{prev.title}</Link>
          </>
        )}
      </div>
      <div>
        {next && (
          <>
            <Link to={next.slug}>{next.title}</Link>
            <HiOutlineArrowRight />
          </>
        )}
      </div>
    </NextPrevLinksContainer>
  );
};

const DocsPageTemplate = ({ data, location, pageContext }) => {
  const { frontmatter, html, tableOfContents } = data.markdownRemark;
  const { webLink, ref } = data.gitRemote;

  const { slug } = data.markdownRemark.fields;
  const githubLink = `${webLink}/blob/${ref}${slug.endsWith('/') ? slug.slice(0, -1) : slug}.md`;

  return (
    <Layout location={location} seo={{}}>
      <div>
        <Navigation.Landing.Topbar isSubPage />
        <Navigation.Docs.Navbar />
        <Navigation.Docs.Mobile />
        <DocsWrapper>
          <Title> {frontmatter.title} </Title>
          <Html.Wrapper>
            <Html.Content html={html} />
            <Html.TableOfContents html={tableOfContents} />
          </Html.Wrapper>
          <DocsFooter>
            <p>
              Found incorrect information, typos or have other suggestions to this documentation
              page?
            </p>
            <CowaitLink.EditOnGithub to={githubLink} title="Edit on Github" />
          </DocsFooter>
          <NextPrevLinks {...pageContext} />
        </DocsWrapper>
      </div>
    </Layout>
  );
};

export default DocsPageTemplate;

export const docsPageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    gitRemote {
      webLink
      ref
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
      fields {
        slug
      }
      html
      tableOfContents
    }
  }
`;
